body {
  margin: 0;
  padding: 0;
  font-family: Prompt, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
td > a > span:hover {
  /* color: #0a82cc !important */
  text-decoration: underline;
}
td > a:not([role='button']) > span {
  color: #4866c7 !important;
}
/* div[role="tooltip"] {
  z-index: 2700;
  position: fixed;
} */
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
/* fallback */
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialicons/v47/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format('woff2');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

div.picker{
  display: inline-block;
}
.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fafafa;
}
/* CSS Spinner from https://projects.lukehaas.me/css-loaders/ */
.loader,
.loader:before,
.loader:after {
  border-radius: 50%;
}
.loader {
  color: #283593;
  font-size: 11px;
  text-indent: -99999em;
  margin: 55px auto;
  position: relative;
  width: 10em;
  height: 10em;
  box-shadow: inset 0 0 0 1em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
.loader:before,
.loader:after {
  position: absolute;
  content: '';
}
.loader:before {
  width: 5.2em;
  height: 10.2em;
  background: #fafafa;
  border-radius: 10.2em 0 0 10.2em;
  top: -0.1em;
  left: -0.1em;
  -webkit-transform-origin: 5.2em 5.1em;
  transform-origin: 5.2em 5.1em;
  -webkit-animation: load2 2s infinite ease 1.5s;
  animation: load2 2s infinite ease 1.5s;
}
.loader:after {
  width: 5.2em;
  height: 10.2em;
  background: #fafafa;
  border-radius: 0 10.2em 10.2em 0;
  top: -0.1em;
  left: 5.1em;
  -webkit-transform-origin: 0px 5.1em;
  transform-origin: 0px 5.1em;
  -webkit-animation: load2 2s infinite ease;
  animation: load2 2s infinite ease;
}
@-webkit-keyframes load2 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load2 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.previews  img {
  height: 100%;
  margin: 0px;
  width: 100%;
}
form div[role="tablist"] {
  overflow-x: auto;
}

.filter-field > div,
.filter-field > div > div,
.filter-field > div.react-datepicker-wrapper > div,
.filter-field > div.react-datepicker-wrapper > div > div
{
  width: 200px
}
.filter-field > div:last-child {
  width: 1em;
}
.filter-field > div.react-datepicker-popper, 
.filter-field > div.react-datepicker-popper > div.react-datepicker {
  width: 240px
}
/* datasheet style */
.equation.cell {
  position: relative;
}
.error.cell {
  background: rgba(255,0,0,0.14);
  font-size: 0.8em;
  color: red;
}
.error.cell > div.text {
  text-align: center;
}
.equation.cell:before {
  content: '';
  width: 0;
  height: 0;
  position: absolute;
  left: 0;
  top: 0;
  border-style: solid;
  border-width: 6px 6px 0 0;
  border-color: #2185d0 transparent transparent transparent;
  z-index: 2;
}

.row-handle.cell {
  width: 1rem;
}

tbody .row-handle.cell, thead .cell:not(.row-handle) {
  cursor: move;
}

.data-grid-container table.data-grid tr {
 background: white;
}
.data-grid-container table.data-grid .drop-target,  .data-grid-container table.data-grid thead .cell.read-only.drop-target {
  background: #6F86FC;
  -webkit-transition: none;
  -o-transition: none;
  transition: none;
  color: white;
}
.data-grid-container table.data-grid thead .cell.read-only {
  -webkit-transition: none;
  -o-transition: none;
  transition: none;
}


.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

@-webkit-keyframes App-logo-spin {
  from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@keyframes App-logo-spin {
  from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

.container {
  max-width: '90vw';
  margin: auto;
}
table.data-grid {
  margin: auto;
  width: 100%;
}
.Select-control, .Select-input, .Select-placeholder,.Select-clear, .Select-placeholder, .Select--single > .Select-control .Select-value {
  height: 15px;
  line-height: 12px;
  font-size: 12px;
  text-align: left;
  border-radius: 0;
  border: 0;
}

.Select-control input{
  height: 16px;
  font-size: 12px;
  padding: 0;
}
.sheet-container {
  display: block;
  padding: 5px;

  -webkit-box-shadow: 0px 0px 6px #CCC;

          box-shadow: 0px 0px 6px #CCC; 
  margin: auto;
  width: 500px;
  margin-top: 20px;
  -webkit-transition: -webkit-box-shadow 0.5s ease-in;
  transition: -webkit-box-shadow 0.5s ease-in;
  -o-transition: box-shadow 0.5s ease-in;
  transition: box-shadow 0.5s ease-in;
  transition: box-shadow 0.5s ease-in, -webkit-box-shadow 0.5s ease-in;
}
.sheet-container:hover {
  -webkit-transition: -webkit-box-shadow 0.5s ease-in;
  transition: -webkit-box-shadow 0.5s ease-in;
  -o-transition: box-shadow 0.5s ease-in;
  transition: box-shadow 0.5s ease-in;
  transition: box-shadow 0.5s ease-in, -webkit-box-shadow 0.5s ease-in;

  -webkit-box-shadow: 0px 0px 1px #CCC;

          box-shadow: 0px 0px 1px #CCC;
}
.sheet-container table.data-grid tr td.cell:not(.selected){
  border: 1px solid #ececec;
}
.sheet-container table.data-grid tr td.cell, .sheet-container table.data-grid tr th.cell {
  font-size: 12px;
}
div.divider {
  margin: 40px 0px;
  height: 1px;
  width: 100%;
  background-color: #EEE;
}
pre {
      display: inline-block;
    background: #333;
    padding: 10px 30px;
    border-left: 2px solid white;
}
.header {
  text-align: center;
  padding: 50px 0px;
  background: #e63946;
  color: #EEE;
  margin-bottom: 50px;
}
.footer-container a {
  color: white;
}
.footer-container {
  margin-top: 50px;
  text-align: center;
    padding: 50px 0px;
    background: #e63946;
    color: #EEE;
}
.add-grocery {
  text-align: left;
  padding: 5px 10px;
  color: #888;
}
.add-button {
  float: right;
    border-radius: 0;
    background: #CCC;
    border-radius: 2px;
    padding: 2px 20px;
    background: #e63946;
    color: white;
    cursor: pointer;
    font-size: 9px;
}
.add-button:hover {
  background: #f17d86;
}
.github-link {
  display: block;
  width: 200px;
  font-size: 12px;
  text-decoration: none;
  margin: auto;
  color: white;
}
.github-link:hover {
  color: #DDD;
}

.form-box {
  background: white;
  width: 100%;
  border-radius: 6px;
  margin: 0 auto 0 auto;
  padding: 20px;
  margin-top: 30px
}

.form-box .btn {
  display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: .375rem .75rem;
    font-size: .9rem;
    line-height: 1.5;
    border-radius: .25rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.form-box .form-group {
  margin-bottom: 1rem;
}
.flexWrapper {
  margin: 0;
  display: flex;
  padding: 0;
  align-items: center;
  margin-bottom: 8px;
  justify-content: space-between;
  -webkit-box-pack: justify;
  -webkit-box-align: center;
}
.flexWrapper > span.label {
   font-size: 12px;
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: 1.2px;
  text-transform: uppercase;
  color: inherit 
}
.description {
  opacity: .8;
  font-size: .9em;
}
.form-box input, .form-box select {
  display: block;
  padding: .375rem .75rem;
  font-size: .9rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  box-sizing: border-box;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #fff;
  color: #2770b3;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color:#386598 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
